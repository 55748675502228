import {useState, useEffect, useReducer, useContext} from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import XMLFilesContext from '../../contexts/XMLFilesContexts';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { getGrowthData, saveGrowthData } from '../../libs/services/fetchGrowthData';
import translations from './dataGridTranslations';


const SpreadSheet = ({ data }) => {

  const { token, user, logout, jwtUser } = useContext(AuthContext);

  const { 
    growthData,
    dataSets,
    growthDataRows,
    growthDataLoaded,
    setGrowthDataLoaded,
    setGrowthData,
    setGrowthDataRows,
    syncGrowthData,
    saveGrowthDataLine,
    deleteGrowthDataLine,
    editGrowthDataLine,
      } = useContext(XMLFilesContext);

  const [ rows, setRows ] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [snackbar, setSnackbar] = useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };



  const handleSaveClick = (params) => async () => {
    const id = params.id;
    //setRows((prev) => prev.map((row, index) => row.id === id? {...savedRow.data, id: index} : row));
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (params) => () => {
    const id = params.id;
    const _id = params.row._id;
    console.log("Deleting params are:", params );
    const rowToDelete = rows.find((row) => row.id === id)[0];
    setRows(rows.filter((row) => row.id !== id));
    deleteGrowthDataLine( token, _id );
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (row) => {
    try {
      const savedRow = saveGrowthDataLine(token, row)
      const updatedRow = {...savedRow.data, id: savedRow.id, isNew: false};
      setSnackbar({ children: 'Dados Salvos com sucesso', severity: 'success' });
      setGrowthDataLoaded( true);
      return updatedRow;
    } catch (error) {
      handleProcessRowUpdateError(error);
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleProcessRowUpdateError = (error) => {
    //setSnackbar({ children: error.message, severity: 'error' });
  };

  // Define the columns for our DataGrid
  const columns = [
    { field: 'data', headerName: 'Data', width: 150, type: 'date',
    valueGetter: (value) => { 
     //console.log("Date Value is: ", value);
      if( value ) {
        return new Date( value ); //value;
      } else {
        return new Date(Date.now());
      }
    },
    editable: true
  },
    { field: 'contatos', headerName: 'Contatos',type: 'number', editable: true },
    { field: 'reunioes', headerName: 'Reuniões', type: 'number', editable: true },

    { field: 'tipo',
      headerName: 'Tipo', 
      type: 'singleSelect',
      editable: true,
      valueOptions: ["externo", "interno"],
    },
    { field: 'contratacoes', headerName: 'Contratações', type: 'number',  editable: true },
    { field: 'nivelAgente', headerName: 'Nível', editable: true, type: 'singleSelect', valueOptions: ['R0', 'R1', 'R2'] },
    { field: 'marketCenter', headerName: 'Market Center', type: 'string' },
    { field: 'editor', headerName: 'Editor' },
    { field: 'time', headerName: 'Time', editable: false },
    {
      field: 'Actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if( isInEditMode ) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={ handleSaveClick( params ) }
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={ handleCancelClick( params.id ) }
              color="inherit"
            />,
          ];
         } else {
          return [
          <GridActionsCellItem disabled={false} icon={<EditIcon/>} onClick={ handleEditClick( params.id ) } label="Edit" />,
          <GridActionsCellItem disabled={false} icon={<DeleteIcon/>} onClick={handleDeleteClick( params )} label="Delete" />,
          ]
        }
      }
    }
    
  ];

  // Prepare the rows for the DataGrid

  //console.log("Rows are: ", rows);

  function addLine( ) {
     let newRowId = rows.length;

    setRows((prev) => [...prev, {
      id: newRowId,
      data: new Date(Date.now()),
      contatos: 0,
      tipo: 'externo',
      reunioes: 0,
      contratacoes: 0,
      agentLevel: 'R0',
      editor: user.fullName,
      time: user.teamId
      }]
    );

    setRowModesModel( (prevModel) => {
      return {
       ...prevModel,
        [newRowId]: { mode: GridRowModes.Edit, fieldToFocus: 'data' }
      }
    })
  }

  useEffect( () => {
    async function fetchData() {
      try {
        setGrowthDataLoaded( false );
        await syncGrowthData( token );
      } catch (error) {
        setSnackbar({ children: error.message, severity: 'error' });
      }}
    fetchData();
  }, [ ]);

  useEffect( () => {
    setRows( growthDataRows );
  }, [ growthDataRows ] );


  return (
    
    <div style={{ width: '100%' }}>

      <DataGrid
        editMode="row"
        rows={rows}
        columns={columns}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        localeText={translations}
        initialState={{
          sorting: {
            sortModel: [{ field: 'data', sort: 'desc' }],
          },
        }}
        slots={{
          toolbar: (props) => {

            return (
              <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={() => {addLine()}}>
                  Novo Registro
                </Button>
                <GridToolbarExport />
                {/* <Input type='text' name='TOTAL' label='Filtro' value="Data/Nivel/Editor/MC(Regional Only)" onChange={() =>{}}/>
                <IconButton>
                  <SearchIcon/>
                </IconButton> */}
                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                  >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                  </Snackbar>
                )}
              </GridToolbarContainer>
            );
          },
        }}
      />
    </div>
  );
};

export default SpreadSheet;

