import { useState } from 'react';
import TextModal from './TextModal';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {
    DATE_VALIDATION,
    CEL_PHONE_VALIDATION,
    FULL_NAME_VALIDATION,
    EMAIL_VALIDATION,
    UF_VALIDATOR,
    GENERAL_NAME_VALIDATOR,
    VALIDA_CPF
} from '../../libs/validators'

const PrimaryOwnerForm = ({
    ownerName,
    setOwnerName,
    ownerCPF,
    setOwnerCPF,
    ownerEmail,
    setOwnerEmail,
    ownerPhone,
    setOwnerPhone,
    ownerType,
    setOwnerType }) => {

  return (
    <>
      <h6>Proprietário Principal</h6>
      <br />
      <br />
      <TextModal
        label='Nome do Proprietário *'
        holder='João dos Santos Silva'
        errorMsg='Informar nome completo (apenas caracteres latinos).'
        validator={(str) => {
          console.log(FULL_NAME_VALIDATION.test(str))
          return !FULL_NAME_VALIDATION.test(str)
        }}
        f={setOwnerName}
      />
      <TextModal
        label='CPF *'
        holder='CPF do Proprietário'
        errorMsg='CPF inválido!'
        validator={(str) => {
          return !VALIDA_CPF(str)
        }}
        f={setOwnerCPF}
      />
      <TextModal
        label='E-Mail *'
        holder='E-Mail do Proprietário'
        errorMsg='E-Mail inválido!'
        validator={(str) => {
          return !EMAIL_VALIDATION.test(str)
        }}
        f={setOwnerEmail}
      />
      <TextField
        variant='outlined'
        label='Telefone do Proprietário *'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setOwnerPhone(e.target.value)}
        value={ownerPhone}
      />
      <br />
      <Autocomplete
        options={['Pessoa Física', 'Pessoa Jurídica']}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        //noOptionsText='Nenhum Usuário'
        value={ownerType}
        onChange={(e, newValue) => {
          setOwnerType(newValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tipo de Proprietário *"
            placeholder="Selecionar Pessoa Física ou Jurídica"
          />
        )}
      />
      <br />
      <br />
    </>
  );
}

export default PrimaryOwnerForm;
