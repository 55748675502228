import './App.css';
import Grid from '@mui/material/Grid'
import Navbar from './views/Navbar';
import listing from './assets/imgs/listing.png'
import listingSelected from './assets/imgs/listingselected.png'
import home from './assets/imgs/home.png'
import homeSelected from './assets/imgs/homeselected.png'
import tasks from './assets/imgs/taks.png'
import tasksSelected from './assets/imgs/tasksselected.png'
import command from './assets/imgs/command.png'
import commandSelected from './assets/imgs/commandselected.png'
import agent from './assets/imgs/agent.png'
import agentSelected from './assets/imgs/agentselected.png'
import edificios from './assets/imgs/edificios.png'
import edificiosSelected from './assets/imgs/edificiosselected.png'
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Listings from './pages/Listings';
import { useLocation } from 'react-router-dom'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from './contexts/AuthContext';
import Login from './pages/Login';
import axios from 'axios';
import EditListing from './pages/EditListing';
import EditPreListing from './pages/EditPreListing';
import Profile from './pages/Profile';
import Users from './pages/Users';
import Pending from './pages/Pending';
import EditUser from './pages/EditUser';
import AddRemovePhotos from './pages/AddRemovePhotos';
import Adm from './pages/Adm';
import EditTeam from './pages/EditTeam';
import EditMc from './pages/EditMc';
import { Tooltip, Divider } from '@mui/material';
import Rejected from './pages/Rejected';
import Loading from './views/Loading';
import Import from './pages/Import';
import TreinamentosCommand from './pages/TreinamentosCommand';
import TreinamentosGrowthCalls from './pages/TreinamentosGrowthCalls';
import TreinamentosHome from './pages/TreinamentosHome';
import TreinamentosGrowthCallsMonth from './pages/TreinamentosGrowthCallsMonth';
import '@progress/kendo-theme-default/dist/all.css';
import TreinamentosAgents from './pages/TreinamentosAgents';
import TreinamentosVideos from './pages/TreinamentosVideos';
import ListingHistory from './pages/ListingHistory';
import UserHistory from './pages/UserHistory';
import ListingRegister from './pages/ListingsRegister';
import ListingsCommand from './pages/ListingsCommand';
import { BASE_URL } from './libs/constants';
import IMPage from './pages/parceriaIM';
import iconTransactionsImob from './assets/imgs/parcerias_iconTransactionsImob.png'
import iconTransactionsImobSelected from './assets/imgs/parcerias_iconTransactionsImobSelected.png'
import iconVirtualDecorator from './assets/imgs/iconVirtualDecorator.png'
import iconVirtualDecoratorSelected from './assets/imgs/iconVirtualDecoratorSelected.png'
import iconFinancialImob from './assets/imgs/iconFinancialImob.png'
import iconFinancialImobSelected from './assets/imgs/iconFinancialImobSelected.png'
import iconLeadsIntegration from './assets/imgs/iconLeadsIntegration.png'
import iconLeadsIntegrationSelected from './assets/imgs/iconLeadsIntegrationSelected.png'
import imIcon from './assets/imgs/imICon.svg'
import imIconSelected from './assets/imgs/imIconSelected.png'
import iconPlatesImob from './assets/imgs/iconPlatesImob.png'
import iconPlatesImobSelected from './assets/imgs/iconPlatesImobSelected.png'
import iconImobPortals from './assets/imgs/iconImobPortals.png'
import iconImobPortalsSelected from './assets/imgs/iconImobPortalsSelected.png'
import iconManageImob from './assets/imgs/iconManageImob.png'
import iconManageImobSelected from './assets/imgs/iconManageImobSelected.png'
import iconJudgeSupport from './assets/imgs/iconJudgeSupport.png'
import iconJudgeSupportSelected from './assets/imgs/iconJudgeSupportSelected.png'
import iconMarketing from './assets/imgs/iconMarketing.png'
import iconMarketingSelected from './assets/imgs/iconMarketingSelected.png'
import sinalizacao from './assets/imgs/sinalizacao.svg'
import ImobCourses from './pages/parceriaCourses';
import ParceriaDecorators from './pages/parceriaDecorators';
import FinanciamentoImobiliario from './pages/parceriaFinanciamentos';
import Leads from './pages/parceriaLeads';
import PlacasImobiliarias from './pages/parceriasSinalizacao';
import PortaisImobiliarios from './pages/parceriaPortais';
import GetsaoImobiliaria from './pages/parceriaGestaoImobiliaria';
import SuporteJuridico from './pages/parceriaJuridico';
import MarketingImob from './pages/parceriaMarketing';
import prelistingsIcon from './assets/imgs/PreListingsIcon.png';
import prelistingsIconSelected from './assets/imgs/PreListingsIconSelected.png';
import PlanilhaGrowth from './pages/PlanilhaGrowth';
import { ptBR } from '@mui/material/locale';
import GestaoExclusividade from './pages/parceriaGestaoExclusiva';

const routes = [
  { label: 'Pendentes', url: '/listings/pending', exact: true, element: <Pending /> },
  { label: 'Reprovados', url: '/listings/rejected', exact: true, element: <Rejected /> },
  { label: 'Imports', url: '/import', exact: true, element: <Import /> },

  { label: 'Parcerias', url: '/parcerias/im', exact: true, element: <IMPage /> },
  { label: 'Command', url: '/command', exact: true, element: <Home /> },
  { label: 'PipeImob', url: '/pipeimob', exact: true, element: <Home /> },

  // ============ Rotas SOUKW ===============
  { label: 'Redirect', url: '/', exact: true, element: <Navigate to={'/soukw/'} /> },
  { label: 'Home', url: '/soukw/', exact: true, element: <Home /> },

  // Listings
  { label: 'Soukw/Portais', url: '/soukw/portals', exact: true, element: <ListingsCommand /> },
  { label: 'Soukw/ListingsLegados', url: '/soukw/listings', exact: true, element: <Listings /> },
  { label: 'Soukw/ListingsLegados', url: '/soukw/prelistings', exact: true, element: <ListingRegister /> },
  { label: 'Soukw/ListingsLegados', url: '/soukw/listings/editlistings/:id', exact: true, element: <EditListing /> },
  { label: 'Soukw/ListingsLegados', url: '/soukw/prelistings/editprelistings/:id', exact: true, element: <EditPreListing /> },
  { label: 'Listing History', url: '/soukw/listings/history/:id', exact: true, element: <ListingHistory /> },
  { label: 'Planilha Growth', url: '/soukw/growth', exact: true, element: <PlanilhaGrowth /> },

  // Treinamentos
  { label: 'Home Treinamentos', url: '/soukw/training', exact: true, element: <TreinamentosHome /> },
  { label: 'Treinamentos Sistemas', url: '/soukw/training/sistemas', exact: true, element: <TreinamentosCommand key={Date.now() + 0} /> },
  { label: 'Treinamentos Agents', url: '/soukw/training/agents', exact: true, element: <TreinamentosAgents type={0} key={Date.now() + 1} /> },
  { label: 'Treinamentos Videos', url: '/soukw/training/videos/:path', exact: true, element: <TreinamentosVideos type={0} key={Date.now() + 2} /> },
  { label: 'Treinamentos Lideranca', url: '/soukw/training/leadership', exact: true, element: <TreinamentosAgents type={1} key={Date.now() + 3} /> },
  { label: 'Growth Calls Agents', url: '/soukw/training/growth-calls-agents', exact: true, element: <TreinamentosGrowthCalls type={0} key={Date.now() + 4} /> },
  { label: 'Growth Calls TL', url: '/soukw/training/growth-calls-tl', exact: true, element: <TreinamentosGrowthCalls type={1} key={Date.now() + 5} /> },
  { label: 'Growth Calls OP', url: '/soukw/training/growth-calls-op', exact: true, element: <TreinamentosGrowthCalls type={2} key={Date.now() + 6} /> },
  { label: 'Growth Calls Regional', url: '/soukw/training/growth-calls-regional', exact: true, element: <TreinamentosGrowthCalls type={3} key={Date.now() + 7} /> },
  { label: 'Growth Calls do Mes', url: '/soukw/training/growth-calls/:year/:month', exact: true, element: <TreinamentosGrowthCallsMonth /> },

  // User/Teams/MC
  { label: 'Soukw/Usuarios', url: '/soukw/users', exact: true, element: <Users /> },
  { label: 'Soukw/Usuarios', url: '/soukw/users/edituser/:id', exact: true, element: <EditUser /> },
  { label: 'Soukw/Usuarios', url: '/soukw/users/history/:id', exact: true, element: <UserHistory /> },

  // ADM
  { label: 'Soukw/Times&MC', url: '/soukw/teams&mc', exact: true, element: <Adm /> },
  { label: 'Soukw/Times&MC', url: '/soukw/teams&mc/editmc/:id', exact: true, element: <EditMc /> },
  { label: 'Soukw/Times&MC', url: '/soukw/teams&mc/editteam/:id', exact: true, element: <EditTeam /> },
  { label: 'Soukw/MeuPrefil', url: '/soukw/myprofile', exact: true, element: <Profile /> },

  // ============ Rotas SOUKW ===============
  // Rotas PARCERIAS
  { label: 'Parcerias', url: '/parcerias', exact: true, element: <Home /> },
  { label: 'Curso Técnico em Transações Imobiliárias', url: '/parcerias/courses', exact: true, element: <ImobCourses /> },
  { label: 'Decoração Virtual', url: '/parcerias/virtualDecorator', exact: true, element: <ParceriaDecorators /> },
  { label: 'Financiamento Imobiliário', url: '/parcerias/financial', exact: true, element: <FinanciamentoImobiliario /> },
  { label: 'Integração de Leads', url: '/parcerias/integrations', exact: true, element: <Leads /> },
  { label: 'Inteligência de Mercado', url: '/parcerias/im', exact: true, element: <IMPage /> },
  { label: 'Placas Imobiliárias', url: '/parcerias/plates', exact: true, element: <PlacasImobiliarias /> },
  { label: 'Marketing', url: '/parcerias/marketing', exact: true, element: <MarketingImob /> },
  { label: 'Portais Imobiliários', url: '/parcerias/portals', exact: true, element: <PortaisImobiliarios /> },
  { label: 'Software de Gestão Imobiliária', url: '/parcerias/manage', exact: true, element: <GetsaoImobiliaria /> },
  { label: 'Suporte Jurídico', url: '/parcerias/judgesupport', exact: true, element: <SuporteJuridico /> },
  { label: 'Gestão de Exclusividades', url: '/parcerias/exclusividades', exact: true, element: <GestaoExclusividade /> },

]
const theme = createTheme({
  palette: {
    primary: {
      main: "#373f51",
    },
    secondary: { main: "#0099a7" },
  },
  ptBR,
});


function App() {
  const location = useLocation();
  const { token, user, logout, loading, setLoading, setToken, setUser, jwtUser, setJWTUser } = useContext(AuthContext);

  useEffect(() => {
    let st = localStorage.getItem("token");
    if (st) {
      let json = JSON.parse(st);
      if (new Date() > new Date(json.expiration)) {
        localStorage.clear();
        setLoading(false);
        return;
      }

      async function getUserData(tkn) {
        const config = {
          headers: { Authorization: `Bearer ${tkn}` }
        };
        try {
          let userRes = await axios.get(BASE_URL + '/users/data', config);
          setToken(json.token);
          setJWTUser(json.jwtUser);
          setUser(userRes.data);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.error(e);
          localStorage.clear();
        }
      }
      getUserData(json.token)
    } else
      setLoading(false);
  }, [])

  const GoToPartner = ({ pageTitle, page, imgSrc, link }) => {
    return (
      <Tooltip title={pageTitle} placement='left'>
        <a style={{}} href={link} target='_blank'>
          <Grid container spacing={0} justifyContent='center' className={'sidebar-box'}>
            <img src={imgSrc} className='sidebar-icon' />
          </Grid>
        </a>
      </Tooltip>

    )
  }
  const GoToSouKw = ({ pageTitle, imgSrc, routeApp, className }) => {
    return (
      <Tooltip title={pageTitle} placement='left'>
        <Link to={routeApp}>
          <Grid container spacing={0} justifyContent='center' className={className}>
            <img src={imgSrc} className='sidebar-icon' />
          </Grid>
        </Link>
      </Tooltip>
    )
  }

  if (loading)
    return (
      <ThemeProvider theme={theme}>
        <Loading />
      </ThemeProvider>
    )

  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ height: '92vh', }}>
        {token &&
          <Grid container spacing={0} style={{ height: '100%' }} justifyContent='center' alignItems={'flex-start'}>
            <Grid item xs={12}>
              <Navbar />
            </Grid>
            <Grid item xs={1} style={{ height: '100%' }}>
              {
                location.pathname.includes('/command') &&
                <div className='sidebar'>
                  <GoToPartner
                    imgSrc={home}
                    link={'https://console.command.kw.com/command'}
                    page={''}
                    pageTitle={'Command'}
                  />
                  <GoToPartner
                    imgSrc={command}
                    link={'https://console.command.kw.com/connect/learning'}
                    page={''}
                    pageTitle={'Connect'}
                  />
                  <GoToPartner
                    imgSrc={'https://images-front-soukw.s3.amazonaws.com/iconCommandMc.svg'}
                    link={'https://console.command.kw.com/cloudmore'}
                    page={''}
                    pageTitle={'Command MC'}
                  />
                  <GoToPartner
                    imgSrc={'https://images-front-soukw.s3.amazonaws.com/iconFrontdoor.svg'}
                    link={'https://console.command.kw.com/frontdoor'}
                    page={''}
                    pageTitle={'Frontdoor'}
                  />
                </div>
              }

              {
                location.pathname.includes('/soukw') &&
                <div className='sidebar'>
                  <GoToSouKw
                    className={location.pathname == '/soukw' ? 'sidebar-box selected' : 'sidebar-box'}
                    imgSrc={location.pathname == '/soukw' ? homeSelected : home}
                    pageTitle={'Home'}
                    routeApp={'/soukw'}
                  />

                  <Tooltip title={"treinamentos"} placement='left'>
                    <a href={"https://novo.soukw.com.br"}>
                      <Grid
                        container spacing={0}
                        justifyContent='center'
                        className={location.pathname == '/soukw/training'
                          ? 'sidebar-box selected'
                          : 'sidebar-box'
                        }>
                        <img src={location.pathname == '/soukw/training'
                          ? commandSelected
                          : command
                        }
                          className='sidebar-icon'
                        />
                      </Grid>
                    </a>
                  </Tooltip>

                  {/* <GoToSouKw
                    className={location.pathname == '/soukw/training' ? 'sidebar-box selected' : 'sidebar-box'}
                    imgSrc={location.pathname == '/soukw/training' ? commandSelected : command}
                    pageTitle={'Treinamentos'}
                    routeApp={'https://novo.soukw.com.br'}
                  /> */}

                  {jwtUser?.canCreateAdd &&
                    <GoToSouKw
                      className={location.pathname == '/soukw/portals' ? 'sidebar-box selected' : 'sidebar-box'}
                      imgSrc={location.pathname == '/soukw/portals' ? edificiosSelected : edificios}
                      pageTitle={'Portais (XML)'}
                      routeApp={'/soukw/portals'}
                    />
                  }
                  {((user?.permissionLevel === 'ADMIN' || user?.permissionLevel === 'SUPER') || (jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin)) &&
                    <GoToSouKw
                      className={location.pathname == '/soukw/users' ? 'sidebar-box selected' : 'sidebar-box'}
                      imgSrc={location.pathname == '/soukw/users'
                        ? 'https://images-front-soukw.s3.amazonaws.com/usersIconSelected.svg'
                        : 'https://images-front-soukw.s3.amazonaws.com/usersIcon.svg'
                      }
                      pageTitle={'Usuarios'}
                      routeApp={'/soukw/users'}
                    />
                  }
                  {(user?.permissionLevel === 'ADMIN' || user?.permissionLevel === 'SUPER') &&
                    <GoToSouKw
                      className={location.pathname == '/soukw/teams&mc' ? 'sidebar-box selected' : 'sidebar-box'}
                      imgSrc={location.pathname == '/soukw/teams&mc' ? tasksSelected : tasks}
                      pageTitle={'Times & MC`s'}
                      routeApp={'/soukw/teams&mc'}
                    />
                  }
                  <GoToSouKw
                    className={location.pathname == '/soukw/myprofile' ? 'sidebar-box selected' : 'sidebar-box'}
                    imgSrc={location.pathname == '/soukw/myprofile' ? agentSelected : agent}
                    pageTitle={'Meu Perfil'}
                    routeApp={'/soukw/myprofile'}
                  />
                  <GoToSouKw
                    className={location.pathname == '/soukw/listings' ? 'sidebar-box selected' : 'sidebar-box'}
                    imgSrc={location.pathname == '/soukw/listings' ? listingSelected : listing}
                    pageTitle={'Listings Legados'}
                    routeApp={'/soukw/listings'}
                  />
                  <GoToSouKw
                    className={location.pathname == '/soukw/prelistings' ? 'sidebar-box selected' : 'sidebar-box'}
                    imgSrc={location.pathname == '/soukw/prelistings' ? prelistingsIconSelected : prelistingsIcon}
                    pageTitle={'Listings Pre-Cadastro'}
                    routeApp={'/soukw/prelistings'}
                  />
                  {((user?.permissionLevel === 'ADMIN' || user?.permissionLevel === 'SUPER') || (jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin)) &&
                    <GoToSouKw
                      className={location.pathname == '/soukw/growth' ? 'sidebar-box selected' : 'sidebar-box'}
                      imgSrc={location.pathname == '/soukw/growth'
                        ? 'https://images-front-soukw.s3.amazonaws.com/hovergrowthSelected.svg'
                        : 'https://images-front-soukw.s3.amazonaws.com/hovergrowth.svg'
                      }
                      pageTitle={'Planilha Growth'}
                      routeApp={'/soukw/growth'}
                    />
                  }
                  {((user?.permissionLevel === 'ADMIN' || user?.permissionLevel === 'SUPER') || (jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin)) &&
                    <Tooltip title={"Transmittal"} placement='left'>
                      <a href={"https://novo.soukw.com.br/soukw/transmittal"}>
                        <Grid
                          container spacing={0}
                          justifyContent='center'
                          className={location.pathname == '/soukw/transmittal'
                            ? 'sidebar-box selected'
                            : 'sidebar-box'
                          }>
                          <img src={location.pathname == '/soukw/transmittal'
                            ? 'https://images-front-soukw.s3.amazonaws.com/transmittal.svg'
                            : 'https://images-front-soukw.s3.amazonaws.com/transmittal.svg'
                          }
                            className='sidebar-icon'
                          />
                        </Grid>
                      </a>
                    </Tooltip>
                  }
                </div>
              }
              {
                location.pathname.includes('/pipeimob') &&
                <div className='sidebar'>
                  <GoToPartner
                    imgSrc={home}
                    link={`https://app.soukw.com.br/estoque/${user.pipeslug}`}
                    page={''}
                    pageTitle={'Estoque'}
                  />
                  <GoToPartner
                    imgSrc={'https://images-front-soukw.s3.amazonaws.com/negociospipe.png'}
                    link={`https://app.soukw.com.br/negocios/${user.pipeslug}`}
                    page={''}
                    pageTitle={'Negócios'}
                  />
                  <GoToPartner
                    imgSrc={'https://images-front-soukw.s3.amazonaws.com/controlespipe.png'}
                    link={`https://app.soukw.com.br/solicitacoes/${user.pipeslug}`}
                    page={''}
                    pageTitle={'Controles'}
                  />
                  <GoToPartner
                    imgSrc={'https://images-front-soukw.s3.amazonaws.com/equipepipe.png'}
                    link={`https://app.soukw.com.br/usuarios/${user.pipeslug}?abrir=usuarios`}
                    page={''}
                    pageTitle={'Equipe'}
                  />
                  <GoToPartner
                    imgSrc={'https://images-front-soukw.s3.amazonaws.com/financeiropipe.png'}
                    link={`https://app.soukw.com.br/cobrancas_associados/${user.pipeslug}`}
                    page={''}
                    pageTitle={'Financeiro'}
                  />
                  <GoToPartner
                    imgSrc={'https://images-front-soukw.s3.amazonaws.com/confirguracoespipe.png'}
                    link={`https://app.soukw.com.br/configuracoes/${user.pipeslug}`}
                    page={''}
                    pageTitle={'Configurações'}
                  />
                  <GoToPartner
                    imgSrc={'https://images-front-soukw.s3.amazonaws.com/integrationintegration.png'}
                    link={`https://app.soukw.com.br/integracoes/${user.pipeslug}`}
                    page={''}
                    pageTitle={'Integrações'}
                  />
                </div>
              }

              {
                location.pathname.includes('/parcerias') &&
                <div className='sidebar'>
                  <Tooltip title='Curso Técnico em Transações Imobiliárias' placement='right'>
                    <Link to={'/parcerias/courses'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/courses' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/courses' ? iconTransactionsImobSelected : iconTransactionsImob} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>

                  <Tooltip title='Decoração Virtual' placement='right'>
                    <Link to={'/parcerias/virtualDecorator'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/virtualDecorator' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/virtualDecorator' ? iconVirtualDecoratorSelected : iconVirtualDecorator} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>

                  <Tooltip title='Financiamento Imobiliário' placement='right'>
                    <Link to={'/parcerias/financial'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/financial' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/financial' ? iconFinancialImobSelected : iconFinancialImob} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>


                  <Tooltip title='Integração de Leads' placement='right'>
                    <Link to={'/parcerias/integrations'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/integrations' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/integrations' ? iconLeadsIntegrationSelected : iconLeadsIntegration} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>


                  <Tooltip title='Inteligência de Mercado' placement='right'>
                    <Link to={'/parcerias/im'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/im' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/im' ? imIconSelected : imIcon} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>

                  <Tooltip title='Portais Imobiliários' placement='right'>
                    <Link to={'/parcerias/portals'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/portals' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/portals' ? iconImobPortalsSelected : iconImobPortals} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>

                  <Tooltip title='Marketing Imobiliaria' placement='right'>
                    <Link to={'/parcerias/marketing'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/marketing' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/marketing' ? iconMarketingSelected : iconMarketing} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>

                  <Tooltip title='Sinalização' placement='right'>
                    <Link to={'/parcerias/plates'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/plates' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/plates' ? sinalizacao : sinalizacao} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>

                  <Tooltip title='Software de Gestão Imobiliária' placement='right'>
                    <Link to={'/parcerias/manage'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/manage' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/manage' ? iconManageImobSelected : iconManageImob} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>


                  <Tooltip title='Suporte Jurídico' placement='right'>
                    <Link to={'/parcerias/judgesupport'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/judgesupport' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/judgesupport' ? iconJudgeSupportSelected : iconJudgeSupport} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>

                  <Tooltip title='Gestão de Exclusividades' placement='right'>
                    <Link to={'/parcerias/exclusividades'}>
                      <Grid container spacing={0} justifyContent='center'
                        className={location.pathname == '/parcerias/exclusividades' ? 'sidebar-box selected' : 'sidebar-box'}>
                        <img src={location.pathname == '/parcerias/exclusividades' ? iconPlatesImobSelected : iconPlatesImob} className='sidebar-icon' />
                      </Grid>
                    </Link>
                  </Tooltip>


                </div>
              }

            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={0} style={{ height: '100%', }} className='content'>
                {
                  !user?.firstAccess &&
                  <Routes>
                    {
                      routes.map((route, i) => {
                        return <Route key={i} path={route.url} exact={route.exact} element={route.element} />
                      })
                    }
                  </Routes>
                }
                {
                  user?.firstAccess &&
                  <Profile firstAccess />
                }
              </Grid>

            </Grid>
          </Grid>
        }
        {!token && <Login />}
      </div>

    </ThemeProvider>
  );
}

export default App;
