//const BASE_URL = 'http://localhost:3000'
//const SOUKW_URL = 'http://localhost:3001/'
 const BASE_URL = 'https://qa.soukw.com.br:3000'
 const SOUKW_URL = 'https://qa.soukw.com.br/'
const LISTINGS_PATH = BASE_URL + '/listings'
const USERS_PATH= BASE_URL + '/users'


module.exports = {
    BASE_URL,
    LISTINGS_PATH,
    USERS_PATH,
    SOUKW_URL
}