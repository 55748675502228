import { TextField } from '@mui/material';
import { useState } from 'react';
import debounce from 'lodash.debounce';

function TextModal ( {label, holder, helper, errorMsg, f, validator, initialValue = '' } ) {

    const [helperText, setHelperText] = useState( helper || '' )
    const [ hasError, setHasError ] = useState( false );
    const [value, setValue] = useState( initialValue )

  
    const handleChange = ( env ) => { 
        //validação e atribuição
        let val = env.target.value;
        setValue(val);
        if( validator !== undefined ) {
            
            let err = validator( val )
            console.log('Validor is defined')
            
            if( err ) {
               console.log( err )
               setHasError(  err )
               setHelperText( errorMsg )
            } else {
                setHasError(  false )
                setHelperText( '' )
            }
        }
        console.log( val )
        f( val );
    }

    //const debouncedHandleChange = debounce(handleChange, 300);

    return <TextField
        variant='outlined'
        label={ label }
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => { handleChange(e) } }
        placeholder={holder}
        helperText={helperText}
        error={hasError}
        value={value}
    />

}

export default TextModal;


/*
TextModal Component
A reusable component for text input.

Props
label: (string, required) The label for the input field.
holder: (string) The placeholder for the input field.
helper: (string) The helper text to display.
errorMsg: (string) The error message to display when input validation fails.
f: (function, required) The function to be called when the input value changes.
validator: (function) A function that takes the input value as argument and returns an error message if the value is invalid.
Example Usage
jsx
Copy code
import TextModal from './TextModal';

<TextModal
  label="Name"
  holder="Enter your name"
  helper="Please enter your full name"
  errorMsg="Name should contain only alphabets"
  f={(val) => console.log(val)}
  validator={(val) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(val)) {
      return 'Name should contain only alphabets';
    }
  }}
/>
Dependencies
@mui/material
react
lodash.debounce
*/
