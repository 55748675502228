import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';

export default function Loading() {
    return (
        <div>
            <CircularProgress style={{ position: 'absolute', right: '50%', top: '40%' }} size={80} />
        </div>
    )
}
