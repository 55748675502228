import React from 'react';
import { TextField } from '@mui/material'
import TextModal from './TextModal';
import {
    DATE_VALIDATION,
    CEL_PHONE_VALIDATION,
    FULL_NAME_VALIDATION,
    EMAIL_VALIDATION,
    UF_VALIDATOR,
    GENERAL_NAME_VALIDATOR,
    VALIDA_CPF
} from '../../libs/validators'
import axios from 'axios';



const AddressForm = ({ 
    cep, setCep, 
    rua, setRua, 
    numero, setNumero, 
    compl, setCompl, 
    bairro, setBairro, 
    estado, setEstado, 
    cidade, setCidade }) => {
  
    const getCEP = async () => {
        try {
            let res = await axios.get('https://viacep.com.br/ws/' + cep + '/json/')
            let data = res.data
            console.log( data )
            setRua(data.logradouro)
            setBairro(data.bairro)
            setEstado(data.uf)
            setCidade(data.localidade)
        } catch (e) {
            alert('CEP não encontrado.')
        }
    
    }
  
    return (
    <>
      <h6>Endereço</h6>
      <br />
      <TextField
        variant='outlined'
        label='CEP *'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setCep(e.target.value)}
        value={cep}
      />
      <button className='btn btnPrimary' onClick={getCEP}>Puxar Endereço Pelo CEP</button>
      <br />
      <br />

    <TextField
        variant='outlined'
        label='Rua *'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setRua(e.target.value)}
        value={rua}
      />
      <TextField
        variant='outlined'
        label='Número *'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setNumero(e.target.value)}
        value={numero}
      />
      <TextField
        variant='outlined'
        label='Complemento'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setCompl(e.target.value)}
        value={compl}
      />
      <TextField
        variant='outlined'
        label='Bairro *'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setBairro(e.target.value)}
        value={bairro}
      />
      <TextField
        variant='outlined'
        label='Estado *'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setEstado(e.target.value)}
        value={estado}
      />
      <TextField
        variant='outlined'
        label='Cidade *'
        color='secondary'
        fullWidth
        style={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(e) => setCidade(e.target.value)}
        value={cidade}
      />
      <br />
      <br />
    </>
  );
};

export default AddressForm;


/**
 * <AddressForm
  cep={cep}
  setCep={setCep}
  rua={rua}
  setRua={setRua}
  numero={numero}
  setNumero={setNumero}
  compl={compl}
  setCompl={setCompl}
  bairro={bairro}
  setBairro={setBairro}
  estado={estado}
  setEstado={setEstado}
  cidade={cidade}
  setCidade={setCidade}
  getCEP={getCEP}
/>
 */