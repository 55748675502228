const  DATE_VALIDATION = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
const CEL_PHONE_VALIDATION = /^[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{4,5}[-\s\.]?[0-9]{4}$/
const FULL_NAME_VALIDATION = /^([A-Za-z0-9\'\-_\.À-ÖÙ-öù-ÿ]{2,})(\s[A-Za-z0-9\'\-_\.À-ÖÙ-öù-ÿ]{1,}){1,}$/
const EMAIL_VALIDATION = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const UF_VALIDATOR = /^[A-Za-z][A-Za-z]$/
const GENERAL_NAME_VALIDATOR = /^([A-Za-z0-9\'\-_\.À-ÖÙ-öù-ÿ]{2,})(\s[A-Za-z0-9\'\-_\.À-ÖÙ-öù-ÿ]{1,})?$/

const VALIDA_CPF = ( strCPF ) => {
    
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10)) ) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;

}

module.exports = {
    DATE_VALIDATION,
    CEL_PHONE_VALIDATION,
    FULL_NAME_VALIDATION,
    EMAIL_VALIDATION,
    UF_VALIDATOR,
    GENERAL_NAME_VALIDATOR,
    VALIDA_CPF
}